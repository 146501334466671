import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import _ from 'lodash';

const customFieldMenuChoiceColumns = `{
    id
    customFieldId
	customFieldMenuChoiceId
    value
    disabled
    numberOrder
	language
}`;

const customFieldValueColumns = `{
    id
    entityId
    customFieldId
    value
    customFieldMenuChoiceValue
    customFieldValueId
}`;

const customFieldRuleColumns = `{
    id
    customFieldId
    dateFormatLayout
    minNumberDigitLimit
    maxNumberDigitLimit
}`;

const customFieldColumns = `{
    id
    customFieldId
    parentType
    label
    type
    defaultValue
    disabled
    userProjectCustomFieldValue
	language
    customFieldMenuChoice ${customFieldMenuChoiceColumns}
    customFieldRule ${customFieldRuleColumns}
    customFieldMenuChoiceList ${customFieldMenuChoiceColumns}
    customFieldValues ${customFieldValueColumns}
}`;

/**
 * get custom field
 * @param {Number} id
 */
export const getCustomField = async (id) => {
	const customFieldId = +id;
	if (!_.isNumber(customFieldId) || customFieldId === 0) return [];

	const query = gql`
            query ($customFieldId: ID!) {
                GetCustomField (CustomFieldId: $customFieldId) ${customFieldColumns}
            }
        `;

	const {
		data: { GetCustomField }
	} = await apollo.query({
		query,
		variables: {
			customFieldId
		},
		fetchPolicy: 'no-cache'
	});

	return GetCustomField;
};

/**
 * get custom fields
 * @param {Object} filter
 */
export const getCustomFields = async (filter = null) => {
	const query = gql`
            query ($filter: AdvancedGlobalFilterInput) {
                GetCustomFieldList (Filter: $filter) ${customFieldColumns}
            }
        `;

	const {
		data: { GetCustomFieldList }
	} = await apollo.query({
		query,
		variables: {
			filter
		},
		fetchPolicy: 'no-cache'
	});

	return GetCustomFieldList;
};

/**
 * add update custom field
 * @param {Number} id
 * @param {Object} input
 * {
 *      parentType
 *      type
 *      defaultValue
 *      customFieldMenuChoice
 *      customFieldRules
 *      userProjectCustomFieldValue
 *      customFieldId
 *      customFieldMenuChoiceList *Array
 *      customFieldValues *Array
 *      label
 * }
 */
export const addUpdCustomField = async (id, input) => {
	const customFieldId = +id;
	if (!_.isNumber(customFieldId)) throw new Error('Invalid ID');

	const mutation = gql`
        mutation ($customFieldId: ID, $input: CustomFieldInput!) {
            AddUpdCustomField(
                CustomFieldId: $customFieldId,
                CustomFieldInput: $input
            ) ${customFieldColumns}
        }
    `;
	const {
		data: { AddUpdCustomField }
	} = await apollo.mutate({
		mutation,
		variables: {
			customFieldId,
			input
		}
	});

	return AddUpdCustomField;
};

/**
 * add update custom fields
 * @param {Array} inputs
 * {
 *      parentType
 *      type
 *      defaultValue
 *      customFieldMenuChoice
 *      customFieldRules
 *      userProjectCustomFieldValue
 *      customFieldId
 *      customFieldMenuChoiceList *Array
 *      customFieldValues *Array
 *      label
 * }
 */
export const addUpdCustomFields = async (inputs) => {
	const mutation = gql`
        mutation ($inputs: [CustomFieldInput!]!) {
            AddUpdSliceCustomField(
                CustomFieldInputs: $inputs
            ) ${customFieldColumns}
        }
    `;
	const {
		data: { AddUpdSliceCustomField }
	} = await apollo.mutate({
		mutation,
		variables: {
			inputs
		}
	});

	return AddUpdSliceCustomField;
};

/**
 * delete custom fields
 * @param {Array} ids
 */
export const deleteCustomFields = async (ids) => {
	if (!_.isArray(ids)) throw new Error('Invalid input, the input is an array.');

	const mutation = gql`
		mutation ($ids: [ID!]!) {
			DeleteCustomField(CustomFieldId: $ids)
		}
	`;
	const {
		data: { DeleteCustomField }
	} = await apollo.mutate({
		mutation,
		variables: {
			ids
		}
	});

	return DeleteCustomField;
};

/**
 * add update custom fields values
 * @param {Array} inputs
 * {
 *      parentTypeId
 *      customFieldId
 *      value
 *      customFieldMenuChoiceValue
 *      customFieldValueId
 * }
 */
export const addUpdCustomFieldValues = async (inputs) => {
	const mutation = gql`
        mutation ($inputs: [CustomFieldValueInput!]!) {
            AddUpdCustomFieldValue(
                CustomFieldValueInputs: $inputs
            ) ${customFieldValueColumns}
        }
    `;
	const {
		data: { AddUpdCustomFieldValue }
	} = await apollo.mutate({
		mutation,
		variables: {
			inputs
		}
	});

	return AddUpdCustomFieldValue;
};

/**
 * add update custom fields values
 * @param {Array} inputs
 * {
 *      customsFieldId
 *      numberOrder
 *      customFieldMenuChoiceId
 *      value
 * }
 */
export const addUpdCustomFieldMenuChoices = async (inputs) => {
	const mutation = gql`
        mutation ($inputs: [CustomFieldMenuChoiceInput!]!) {
            AddUpdCustomFieldMenuChoice(
                CustomFieldMenuChoiceInput: $inputs
            ) ${customFieldMenuChoiceColumns}
        }
    `;
	const {
		data: { AddUpdCustomFieldMenuChoice }
	} = await apollo.mutate({
		mutation,
		variables: {
			inputs
		}
	});

	return AddUpdCustomFieldMenuChoice;
};

/**
 * get custom field menus
 * @param {Number} id
 */
export const getCustomFieldMenus = async (id) => {
	const customFieldId = +id;
	if (!_.isNumber(customFieldId) || customFieldId === 0) return [];

	const query = gql`
            query ($customFieldId: ID!) {
                GetCustomFieldMenuList (CustomFieldId: $customFieldId) ${customFieldMenuChoiceColumns}
            }
        `;

	const {
		data: { GetCustomFieldMenuList }
	} = await apollo.query({
		query,
		variables: {
			customFieldId
		},
		fetchPolicy: 'no-cache'
	});

	return GetCustomFieldMenuList;
};

/**
 * add update custom field rules
 * @param {Number} id
 * @param {Array} input
 *
 * {
 *      customFieldId
 *      dateFormatLayout
 *      minNumberDigitLimit
 *      maxNumberDigitLimit
 * }
 *
 * @param {Number} ruleId
 */
export const addUpdCustomFieldRule = async (id, input, ruleId) => {
	const customFieldId = +id;
	const customFieldRuleId = +ruleId;
	if (!_.isNumber(customFieldId)) throw new Error('Invalid Custom Field ID');

	const mutation = gql`
        mutation ($customFieldId: ID!, $input: CustomFieldRuleInput!, $customFieldRuleId: ID) {
            AddUpdCustomFieldRule(
                CustomFieldId: $customFieldId,
                CustomFieldRuleInput: $input,
                CustomFieldRulesId: $customFieldRuleId
            ) ${customFieldRuleColumns}
        }
    `;
	const {
		data: { AddUpdCustomFieldRule }
	} = await apollo.mutate({
		mutation,
		variables: {
			customFieldId,
			input,
			customFieldRuleId
		}
	});

	return AddUpdCustomFieldRule;
};

export const deleteCustomFieldMenuChoices = async (ids) => {
	if (!_.isArray(ids)) throw new Error('Invalid input, the input is an array.');

	const mutation = gql`
		mutation ($ids: [ID!]!) {
			DeleteCustomFieldMenuChoice(CustomFieldMenuChoiceIds: $ids)
		}
	`;
	const {
		data: { DeleteCustomFieldMenuChoice }
	} = await apollo.mutate({
		mutation,
		variables: {
			ids
		}
	});

	return DeleteCustomFieldMenuChoice;
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.input
    ? _c("div", { staticClass: "animated fadeIn d-flex align-items-center" }, [
        +_vm.input.type === _vm.CUSTOM_FIELD_TYPE.Checkbox
          ? _c(
              "div",
              { staticClass: "w-100" },
              [
                _c("b-form-group", {
                  staticClass: "w-100",
                  attrs: { label: `${_vm.input.label.toUpperCase()}` },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ ariaDescribedby }) {
                          return [
                            _c(
                              "b-form-checkbox-group",
                              {
                                attrs: {
                                  "aria-describedby": ariaDescribedby,
                                  name: _vm.nameGroup,
                                  disabled: _vm.input.disabled,
                                },
                                model: {
                                  value: _vm.fieldModel,
                                  callback: function ($$v) {
                                    _vm.fieldModel = $$v
                                  },
                                  expression: "fieldModel",
                                },
                              },
                              _vm._l(
                                _vm.input.customFieldMenuChoiceList,
                                function (choice, index) {
                                  return _c(
                                    "b-form-checkbox",
                                    {
                                      key: index,
                                      attrs: {
                                        size: "lg",
                                        value: +choice.id,
                                        disabled: choice.disabled,
                                      },
                                      on: {
                                        change: _vm.handleCheckboxGroupSelected,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "1px" },
                                        },
                                        [_vm._v(_vm._s(choice.value))]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3269845886
                  ),
                }),
              ],
              1
            )
          : +_vm.input.type === _vm.CUSTOM_FIELD_TYPE.Date
          ? _c(
              "div",
              { staticClass: "d-flex justify-content-start w-100" },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "w-100",
                    attrs: { label: `${_vm.input.label.toUpperCase()}` },
                  },
                  [
                    _c("v-date-picker", {
                      attrs: {
                        attributes: _vm.calendarAttrs,
                        locale: _vm.lang,
                        masks: _vm.masks,
                        disabled: _vm.input.disabled,
                      },
                      on: { dayclick: _vm.handleDate },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ inputValue, togglePopover }) {
                              return [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      staticClass: "br-none",
                                      attrs: {
                                        value: inputValue,
                                        placeholder: "DD/MM/YYYY",
                                        readonly: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          !_vm.input.disabled
                                            ? togglePopover()
                                            : void 0
                                        },
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      [
                                        _c(
                                          "b-input-group-text",
                                          {
                                            staticClass:
                                              "bg-color-white input-group-text-bg-white",
                                            class: {
                                              "cursor-pointer":
                                                !_vm.input.disabled,
                                            },
                                            on: {
                                              click: function ($event) {
                                                !_vm.input.disabled
                                                  ? togglePopover()
                                                  : void 0
                                              },
                                            },
                                          },
                                          [
                                            _c(_vm.getLucideIcon("Calendar"), {
                                              tag: "component",
                                              attrs: {
                                                color: "rgba(6, 38, 62, .6)",
                                                size: 18,
                                                "stroke-width": 2.25,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3100374952
                      ),
                      model: {
                        value: _vm.fieldModel,
                        callback: function ($$v) {
                          _vm.fieldModel = $$v
                        },
                        expression: "fieldModel",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : +_vm.input.type === _vm.CUSTOM_FIELD_TYPE.Dropdown
          ? _c(
              "div",
              { staticClass: "w-100" },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "w-100",
                    attrs: { label: `${_vm.input.label.toUpperCase()}` },
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        label: "value",
                        placeholder: "...",
                        options: _vm.choices,
                        reduce: (option) => option.id,
                        selectable: _vm.selectableItem,
                        disabled: _vm.input.disabled,
                      },
                      on: { input: _vm.handleChoiceSelected },
                      model: {
                        value: _vm.fieldModel,
                        callback: function ($$v) {
                          _vm.fieldModel = $$v
                        },
                        expression: "fieldModel",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : +_vm.input.type === _vm.CUSTOM_FIELD_TYPE.Freetext
          ? _c(
              "div",
              { staticClass: "w-100" },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "w-100",
                    attrs: { label: `${_vm.input.label.toUpperCase()}` },
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "text",
                        autocomplete: "off",
                        disabled: _vm.input.disabled,
                      },
                      on: { update: _vm.handleTextChanged },
                      model: {
                        value: _vm.fieldModel,
                        callback: function ($$v) {
                          _vm.fieldModel = $$v
                        },
                        expression: "fieldModel",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : +_vm.input.type === _vm.CUSTOM_FIELD_TYPE.Number
          ? _c(
              "div",
              { staticClass: "w-100" },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "w-100",
                    attrs: { label: `${_vm.input.label.toUpperCase()}` },
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        type: "number",
                        min: "0",
                        placeholder: "0",
                        disabled: _vm.input.disabled,
                      },
                      on: { update: _vm.handleNumberChanged },
                      model: {
                        value: _vm.fieldModel,
                        callback: function ($$v) {
                          _vm.fieldModel = $$v
                        },
                        expression: "fieldModel",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : +_vm.input.type === _vm.CUSTOM_FIELD_TYPE.Percentage
          ? _c(
              "div",
              { staticClass: "w-100" },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "w-100",
                    attrs: { label: `${_vm.input.label.toUpperCase()}` },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            type: "number",
                            step: "0.01",
                            max: "100",
                            min: "0",
                            placeholder: "0",
                            disabled: _vm.input.disabled,
                          },
                          on: { update: _vm.handlePercentChanged },
                          model: {
                            value: _vm.fieldModel,
                            callback: function ($$v) {
                              _vm.fieldModel = $$v
                            },
                            expression: "fieldModel",
                          },
                        }),
                        _c(
                          "b-input-group-append",
                          [
                            _c(
                              "b-input-group-text",
                              [
                                _c(_vm.getLucideIcon("Percent"), {
                                  tag: "component",
                                  attrs: {
                                    color: "#06263E",
                                    size: 16,
                                    "stroke-width": 2,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : +_vm.input.type === _vm.CUSTOM_FIELD_TYPE.Radiobutton
          ? _c(
              "div",
              { staticClass: "w-100" },
              [
                _c("b-form-group", {
                  staticClass: "w-100",
                  attrs: { label: `${_vm.input.label.toUpperCase()}` },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ ariaDescribedby }) {
                          return [
                            _c(
                              "b-form-radio-group",
                              {
                                attrs: {
                                  "aria-describedby": ariaDescribedby,
                                  name: _vm.nameGroup,
                                  disabled: _vm.input.disabled,
                                },
                                model: {
                                  value: _vm.fieldModel,
                                  callback: function ($$v) {
                                    _vm.fieldModel = $$v
                                  },
                                  expression: "fieldModel",
                                },
                              },
                              _vm._l(
                                _vm.input.customFieldMenuChoiceList,
                                function (choice, index) {
                                  return _c(
                                    "b-form-radio",
                                    {
                                      key: index,
                                      staticClass: "mt-2",
                                      attrs: {
                                        size: "lg",
                                        value: +choice.id,
                                        disabled: choice.disabled,
                                      },
                                      on: {
                                        change: _vm.handleRadioGroupSelected,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-top": "0px" },
                                        },
                                        [_vm._v(_vm._s(choice.value))]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    485730715
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div class="animated fadeIn d-flex align-items-center" v-if="input">
		<!-- <pre>{{ nameModel }}</pre> -->
		<div class="w-100" v-if="+input.type === CUSTOM_FIELD_TYPE.Checkbox">
			<b-form-group :label="`${input.label.toUpperCase()}`" class="w-100" v-slot="{ ariaDescribedby }">
				<b-form-checkbox-group v-model="fieldModel" :aria-describedby="ariaDescribedby" :name="nameGroup" :disabled="input.disabled">
					<b-form-checkbox
						@change="handleCheckboxGroupSelected"
						size="lg"
						v-for="(choice, index) in input.customFieldMenuChoiceList"
						:key="index"
						:value="+choice.id"
						:disabled="choice.disabled"
					>
						<div style="margin-top: 1px">{{ choice.value }}</div>
					</b-form-checkbox>
				</b-form-checkbox-group>
			</b-form-group>
		</div>
		<div class="d-flex justify-content-start w-100" v-else-if="+input.type === CUSTOM_FIELD_TYPE.Date">
			<b-form-group :label="`${input.label.toUpperCase()}`" class="w-100">
				<v-date-picker v-model="fieldModel" :attributes="calendarAttrs" :locale="lang" :masks="masks" @dayclick="handleDate" :disabled="input.disabled">
					<template v-slot="{ inputValue, togglePopover }">
						<b-input-group>
							<b-form-input
								:value="inputValue"
								class="br-none"
								placeholder="DD/MM/YYYY"
								@click="!input.disabled ? togglePopover() : void 0"
								readonly
							/>
							<b-input-group-append>
								<b-input-group-text
									class="bg-color-white input-group-text-bg-white"
									:class="{ 'cursor-pointer': !input.disabled }"
									@click="!input.disabled ? togglePopover() : void 0"
								>
									<component :is="getLucideIcon('Calendar')" color="rgba(6, 38, 62, .6)" :size="18" :stroke-width="2.25" />
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</template>
				</v-date-picker>
			</b-form-group>
		</div>
		<div class="w-100" v-else-if="+input.type === CUSTOM_FIELD_TYPE.Dropdown">
			<b-form-group :label="`${input.label.toUpperCase()}`" class="w-100">
				<v-select
					v-model="fieldModel"
					label="value"
					placeholder="..."
					:options="choices"
					:reduce="(option) => option.id"
					:selectable="selectableItem"
					:disabled="input.disabled"
					@input="handleChoiceSelected"
				/>
			</b-form-group>
		</div>
		<div class="w-100" v-else-if="+input.type === CUSTOM_FIELD_TYPE.Freetext">
			<b-form-group :label="`${input.label.toUpperCase()}`" class="w-100">
				<b-form-input v-model="fieldModel" type="text" autocomplete="off" :disabled="input.disabled" @update="handleTextChanged" />
			</b-form-group>
		</div>
		<div class="w-100" v-else-if="+input.type === CUSTOM_FIELD_TYPE.Number">
			<b-form-group :label="`${input.label.toUpperCase()}`" class="w-100">
				<b-form-input v-model="fieldModel" type="number" min="0" placeholder="0" :disabled="input.disabled" @update="handleNumberChanged" />
			</b-form-group>
		</div>
		<div class="w-100" v-else-if="+input.type === CUSTOM_FIELD_TYPE.Percentage">
			<b-form-group :label="`${input.label.toUpperCase()}`" class="w-100">
				<b-input-group>
					<b-form-input
						v-model="fieldModel"
						type="number"
						step="0.01"
						max="100"
						min="0"
						placeholder="0"
						:disabled="input.disabled"
						@update="handlePercentChanged"
					/>
					<b-input-group-append>
						<b-input-group-text>
							<component :is="getLucideIcon('Percent')" color="#06263E" :size="16" :stroke-width="2" />
						</b-input-group-text>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>
		</div>
		<div class="w-100" v-else-if="+input.type === CUSTOM_FIELD_TYPE.Radiobutton">
			<b-form-group :label="`${input.label.toUpperCase()}`" class="w-100" v-slot="{ ariaDescribedby }">
				<b-form-radio-group v-model="fieldModel" :aria-describedby="ariaDescribedby" :name="nameGroup" :disabled="input.disabled">
					<b-form-radio
						@change="handleRadioGroupSelected"
						size="lg"
						v-for="(choice, index) in input.customFieldMenuChoiceList"
						:key="index"
						:value="+choice.id"
						:disabled="choice.disabled"
						class="mt-2"
					>
						<div style="margin-top: 0px">{{ choice.value }}</div>
					</b-form-radio>
				</b-form-radio-group>
			</b-form-group>
		</div>
	</div>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import { CUSTOM_FIELD_TYPE } from '@/shared/constants';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import moment from 'moment';
import { noTilde } from '~utils';

export default {
	name: 'CustomFieldTemplate',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		'v-date-picker': DatePicker
	},
	props: {
		value: {
			type: [Array, String, Number, Boolean],
			required: false,
			default: false
		},
		field: {
			type: Object,
			required: true,
			default: null
		},
		nameModel: {
			type: String,
			required: false,
			default: null
		},
		nameGroup: {
			type: String,
			required: false,
			default: null
		},
		index: {
			type: Number,
			required: false,
			default: 0
		}
	},
	computed: {
		fieldModel: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		},
		choices() {
			let options = this.input.customFieldMenuChoiceList;

			options.sort((a, b) => {
				if (noTilde(a.value.trim()).toLowerCase() < noTilde(b.value.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.value.trim()).toLowerCase() > noTilde(b.value.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return options;
		}
	},
	data() {
		let calendarAttrs = [
			{
				key: 'today',
				highlight: {
					color: 'blue',
					fillMode: 'outline'
				},
				dates: new Date()
			}
		];

		return {
			CUSTOM_FIELD_TYPE,
			masks: {
				input: 'DD/MM/YYYY'
			},
			moment,
			input: null,
			calendarAttrs
		};
	},
	methods: {
		handleDate(payload) {
			this.emitPayload(payload.id + 'T00:00:00Z');
		},
		handleChoiceSelected(payload) {
			this.emitPayload(payload);
		},
		handleCheckboxGroupSelected(payload) {
			this.emitPayload(payload);
		},
		handleTextChanged(payload) {
			this.emitPayload(payload);
		},
		handleNumberChanged(payload) {
			payload = parseFloat(payload);
			this.emitPayload(payload);
		},
		selectableItem(option) {
			return _.isNil(option.disabled) || option.disabled === false;
		},
		handlePercentChanged(payload) {
			payload = parseFloat(payload);
			this.emitPayload(payload);
		},
		handleRadioGroupSelected(payload) {
			this.emitPayload(payload);
		},
		emitPayload(payload) {
			let item = {
				...this.input,
				[this.nameModel]: payload,
				index: this.index
			};

			this.$emit('custom-field-template:payload', {
				...item
			});
		}
	},
	watch: {
		field: {
			handler(value) {
				if (!_.isNil(value)) {
					this.input = value;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
